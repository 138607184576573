<template>
  <div class="home" v-if="riskInfo.yearlyRiskState !== -1">
    <div class="image">
      <van-image
        width="100px"
        style="padding:20px;"
        height="100px"
        fit="contain"
        src="https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png" />
    </div>
    <div class="theme-color">
      <div v-if="riskInfo.yearlyRiskState === 1 || riskInfo.yearlyRiskState === 2">风险评估更新审批中</div>
      <div v-if="riskInfo.yearlyRiskState === 4">风险评估更新已退回</div>
      <div class="tip" v-if="riskInfo.yearlyRiskState === 1 || riskInfo.yearlyRiskState === 2">
        我们正在认真审批您的资料，审批后我们将短信通知，请留意！
      </div>
      <div class="tip" v-if="riskInfo.yearlyRiskState === 4">
        {{riskInfo.yearlyRiskInfo}}
      </div>
    </div>
    <div
      v-if="riskInfo.yearlyRiskState === 4"
      style="width: 100%;text-align: center;margin-top: 20px;"
    >
      <van-button type="primary" style="width: 100%;margin: auto;" @click="handleRiskUpdate">
        重测风险评估
      </van-button>
    </div>
  </div>
</template>

<script>
import { Image as VanImage, Button } from 'vant';
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import httpApi from '../../utils/httpApi';

export default {
  name: 'Home',
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },
  methods: {
    async handleRiskUpdate() {
      this.$store.commit('setIsYearlyRisk', true);
      this.$router.push({ path: '/riskOrientation/step_one' });
    },
  },
  setup() {
    const riskInfo = reactive({ yearlyRiskInfo: '', yearlyRiskState: -1 });
    onMounted(async () => {
      const store = useStore();
      store.commit('setStepsShow', false);
      store.commit('setTitleValue', '查询');
      store.commit('setTitleLeft', false);
      await httpApi.submitLog({ type: 'yearlyOpenAccountLogin' });
      const riskUpdateInfo = await httpApi.getYearlyRiskTropismInfo();
      if (!riskUpdateInfo) return;
      riskInfo.yearlyRiskInfo = riskUpdateInfo.data.yearlyRiskInfo;
      riskInfo.yearlyRiskState = riskUpdateInfo.data.yearlyRiskState;
    });
    return { riskInfo };
  },
};
</script>

<style lang="less">
  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home {
    padding-left: @space-0;
    padding-right: @space-0;
  }
  .tip {
    text-align: left;
    margin-top: 20px;
  }
</style>
